const EXAMPLE_LINK = 'https://www.facebook.com'

const nodeStyles = { width: 94, border: 'none', padding: 0, textAlign: 'center' }
export {nodeStyles}

const getNode = (id, imageUrl, name, position, type, type2) => {
	return {
		id,
		type: 'element',
		style: nodeStyles,
		data: { id, name, imageUrl, url: EXAMPLE_LINK, cac: '', ltv: '', leads: 100, type, type2 },
		sourcePosition: 'right',
		targetPosition: 'left',
		position
	}
}
export {getNode}

const edgeLabelStyles = { labelBgPadding: [8, 4], labelBgBorderRadius: 4, labelBgStyle: { fill: '#FFCC00', color: '#fff', fillOpacity: 0.7 } }

const getEdge = (params, sourceNode) => {
	return {
		...params,
		...edgeLabelStyles,
		type: 'link',
		label: sourceNode.data.type === 'acquisition' ? '100%' : 'Not set',
		data: { rate: sourceNode.data.type === 'acquisition' ? 1 : 0 },
		animated: true
	}
}
export {getEdge}

const updateNodesHits = (nodes, edges, sourceNodes) => {
	
	if (!nodes) return []

	const connectedNodes = new Set()
	sourceNodes.forEach(sourceNode => {
		const sourceNodeLinks = edges.filter(e => e.source === sourceNode?.id)
		sourceNodeLinks.forEach(s => {

			// add connected node
			const node = nodes.find(e => e.id === s.target)
			if (!node) return
			connectedNodes.add(node)

			// update hits
			const nodeData = sourceNode?.data
			if (!nodeData?.leads && !nodeData?.hits) return
			const hits = nodeData?.type === 'acquisition' ? nodeData?.leads : nodeData?.hits
			node.data.hits += hits * s.data.rate
		})
	})
	return [...connectedNodes]
}

const getNodeCac = (c) => (!isNaN(parseFloat(c.data.leads)) && !isNaN(parseFloat(c.data.cac))) ? parseFloat(c.data.leads) * parseFloat(c.data.cac) : 0
const calculateTotalCac = (funnelNodes) => {
    const acquisitionNodes = funnelNodes?.filter(e => e.data.type === 'acquisition') ?? []
    return acquisitionNodes.reduce((p, c) => getNodeCac(c) + p, 0)
}
export {calculateTotalCac}

const getNodeLtv = (c) => (!isNaN(parseFloat(c.data.hits)) && !isNaN(parseFloat(c.data.ltv))) ? parseFloat(c.data.hits) * parseFloat(c.data.ltv) : 0
const calculateTotalLtv = (funnelNodes) => {
    const conversionNodes = funnelNodes?.filter(e => e.data.type === 'conversion') ?? []
    return conversionNodes.reduce((p, c) => getNodeLtv(c) + p, 0)
}
export {calculateTotalLtv}

const calculateHits = (nodes, edges) => {

	if (!edges || !nodes) return []
	
	// reset hits to 0
	nodes.forEach(e => { if (e.type === 'element') e.data.hits = 0 })

	// calculate hits recursively
	let connectedNodes = nodes.filter(e => e.data.type === 'acquisition')
	while (connectedNodes.length > 0) connectedNodes = updateNodesHits(nodes, edges, connectedNodes)
	return nodes
}
export {calculateHits}

const removeBrokenLinks = (nodes, edges) => {
	if (!edges || !nodes) return []
	return edges.filter(e => nodes.find(n =>  n.id === e.target)?.data.type !== 'acquisition')
}
export {removeBrokenLinks}
