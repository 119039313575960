import axios from "axios"

const getFromLocalImage = (url) => {
	let d
	try {
		d = new URL(url)
	} catch (error) {
		return url
	}
	let newUrl
	if (url === 'youtube.com' || url === 'www.youtube.com' || d.hostname.endsWith('youtube.com'))
	newUrl = '/static/sites/youtube.png'
	else if (d.hostname.endsWith('instagram.com')) 
	newUrl = '/static/sites/instagram.png'
	else if (d.hostname.endsWith('bing.com')) 
	newUrl = '/static/sites/bing.png'
	else if (d.hostname.endsWith('facebook.com')) 
	newUrl = '/static/sites/facebook.png'
	else if (d.hostname.endsWith('pinterest.com')) 
	newUrl = '/static/sites/pinterest.png'
	else if (d.hostname.endsWith('pinterest.com')) 
	newUrl = '/static/sites/pinterest.png'
	else if (d.hostname.endsWith('google.com') && !d.hostname.endsWith('mail.google.com')) 
	newUrl = '/static/sites/google.png'
	else if (d.hostname.endsWith('twitter.com')) 
	newUrl = '/static/sites/twitter.png'
	else if (d.hostname.endsWith('reddit.com')) 
	newUrl = '/static/sites/reddit.png'
	else if (d.hostname.endsWith('snapchat.com')) 
	newUrl = '/static/sites/snapchat.png'
	else if (d.hostname.endsWith('ycombinator.com')) 
	newUrl = '/static/sites/ycombinator.png'
	else if (d.hostname.endsWith('producthunt.com')) 
	newUrl = '/static/sites/producthunt.png'
	else if (d.hostname.endsWith('gmail.com') || d.hostname.endsWith('mail.google.com')) 
	newUrl = '/static/sites/gmail.png'
	else if (d.hostname.endsWith('yahoo.com')) 
	newUrl = '/static/sites/yahoo.png'
	else if (d.hostname.endsWith('outlook.com') || d.hostname.endsWith('outlook.live.com')) 
	newUrl = '/static/sites/outlook.png'
	
	return newUrl
}
export {getFromLocalImage}

const getFromRemote = async (url) => {
	try {
		const {data} = await axios.get(`/api/helper/image?url=${url}`)
		return data.imageUrl
	} catch {
		return
	}
}
export {getFromRemote}