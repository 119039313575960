import { useState, useRef, useContext } from 'react'

import { AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay } from '@chakra-ui/react'
import { Icon, Button, IconButton } from "@chakra-ui/react"

import { TrashIcon } from '@heroicons/react/20/solid'

import { FunnelsContext } from '../../store/funnels'
import { UserContext } from '../../store/user'

import { useRouter } from 'next/router'

import axios from 'axios'

const DeleteModal = ({isOpen, onClose}) => {
	const cancelRef = useRef()
	const { state: funnelsState, dispatch: funnelsDispatch } = useContext(FunnelsContext)
	const { state: userState  } = useContext(UserContext)
	const router = useRouter()

	const deleteFunnel = async () => {
		
		// delete from store
		const newFunnels = funnelsState.funnels.filter(r => r.id !== router.query.funnelId)
		funnelsDispatch({ type: 'SET_FUNNELS', value: newFunnels })
		funnelsDispatch({ type: 'SET_FUNNEL_INDEX', value: 0 })

		// redirect
		if (newFunnels.length === 0) {
			funnelsDispatch({ type: 'SET_FUNNEL_NODES', value: [] })
			funnelsDispatch({ type: 'SET_FUNNEL_EDGES', value: [] })
			router.push('/', null, { shallow: true })
		} else {
			router.push(`/funnels/${newFunnels[0].id}`)
		}

		// delete from db
		await axios.delete(`/api/funnels/${router.query.funnelId}`, { headers: { 'Content-Type': 'application/json', authorization: userState.token } })
	}
	return (
		<AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
			<AlertDialogOverlay>
				<AlertDialogContent>
					<AlertDialogHeader fontSize="lg" fontWeight="bold">
						Delete Funnel
									</AlertDialogHeader>
					<AlertDialogBody>
						Are you sure? You can't undo this action afterwards.
									</AlertDialogBody>
					<AlertDialogFooter>
						<Button ref={cancelRef} onClick={onClose}>Cancel</Button>
						<Button colorScheme="red" onClick={() => { deleteFunnel(); onClose() }} ml={3}>Delete</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	)
}
export default DeleteModal