import { useEffect, useContext, useRef, useMemo, useCallback } from 'react'
import ReactFlow, { ReactFlowProvider, addEdge, applyNodeChanges, applyEdgeChanges } from 'reactflow'

import { Spinner, Flex, Tooltip, IconButton, Text, Icon, Box, useDisclosure } from '@chakra-ui/react'

import { PlusIcon, MinusIcon, MegaphoneIcon, BugAntIcon } from '@heroicons/react/20/solid'

import BottomBarContainer from '../bottombar/BottomBarContainer'
import ProfitPanel from '../panels/ProfitPanel'

import CanvasElement from './CanvasElement'
import CanvasBackground from './CanvasBackground'

import IssueModal from '../modals/IssueModal'
import ThoughtsModal from '../modals/ThoughtsModal'

import { uuidv4 } from '../../utils/client'
import { calculateHits, getNode, getEdge } from '../../utils/client/nodes'
import { makeElementScrollable } from '../../utils/client/html'
import { FunnelsContext } from '../../store/funnels'
import { UserContext } from '../../store/user'

const DELETE_KEY_CODE = 46
const BottomPanel = ({ showTooltip = false }) => {
	
	const { state: funnelsState } = useContext(FunnelsContext)

	const { isOpen: isOpenIssueModal, onOpen: onOpenIssueModal, onClose: onCloseIssueModal } = useDisclosure()
	const { isOpen: isOpenThoughtsModal, onOpen: onOpenThoughtsModal, onClose: onCloseThoughtsModal } = useDisclosure()
	return (
		<>
			<IssueModal isOpen={isOpenIssueModal} onClose={onCloseIssueModal} />
			<ThoughtsModal isOpen={isOpenThoughtsModal} onClose={onCloseThoughtsModal} />
			<Box position="absolute" bottom="12px" right={"20px"}>
				<Flex justifyContent="center" alignItems="center">
					<Tooltip label="Zoom In" isDisabled={!showTooltip}>
						<IconButton icon={<Icon as={PlusIcon} fontSize="20px"/>} zIndex={5} onClick={funnelsState.instance?.zoomIn} />
					</Tooltip>
					<Tooltip label="Zoom Out" isDisabled={!showTooltip}>
						<IconButton icon={<Icon as={MinusIcon} fontSize="20px"/>} zIndex={5} marginLeft="4" onClick={funnelsState.instance?.zoomOut} />
					</Tooltip>
					<Tooltip label="Submit an issue" isDisabled={!showTooltip}>
						<IconButton icon={<Icon as={BugAntIcon} />} zIndex={5} marginLeft="4" onClick={onOpenIssueModal} />
					</Tooltip>
					<Tooltip label="Share your thoughts" isDisabled={!showTooltip}>
						<IconButton colorScheme="purple" icon={<Icon as={MegaphoneIcon} />} zIndex={5} marginLeft="4" onClick={onOpenThoughtsModal} />
					</Tooltip>
				</Flex>
			</Box>
	</>
)}

const CanvasContainer = () => {

	const {state: funnelsState, dispatch: funnelsDispatch} = useContext(FunnelsContext)
	const {state: userState, disclosures} = useContext(UserContext)

	const bottomBarRef = useRef()

	const onConnect = (params) => {

		// HACKY - fixed dynamic handles (1)
		const targetNode = funnelsState.funnelNodes.find(e => e.id === params.target)
		const sourceNode = funnelsState.funnelNodes.find(e => e.id === params.source)

		const newEdges = addEdge(getEdge(params, sourceNode), funnelsState.funnelEdges)
		funnelsDispatch({ type: 'SET_FUNNEL_EDGES', value: newEdges })

		if (targetNode.data.type !== 'acquisition') {
			funnelsDispatch({ type: 'SET_FUNNEL_NODES', value: calculateHits(funnelsState.funnelNodes, newEdges) })
		}

		funnelsDispatch({ type: 'SET_ABOUT_TO_SAVE', value: true })
		gtag('event', 'add_link', { target_name: targetNode.data.name, source_name: sourceNode.data.name })
	}

	const onInit = (instance) => {
		funnelsDispatch({ type: 'SET_INSTANCE', value: instance })
	}

	const onNodeDragStop = () => {
		funnelsDispatch({ type: 'SET_ABOUT_TO_SAVE', value: true })
	}

	const onDragOver = (e) => {
		e.preventDefault()
		e.dataTransfer.dropEffect = 'move'
	}

	const onDrop = (event) => {
		event.preventDefault()
		
		// get step data
		const dataStr = event.dataTransfer.getData('application/reactflow')
		if (dataStr === '') return
		const data = JSON.parse(dataStr)
		
		// create a new node
		const id = uuidv4()
		const type2 = data.name === 'URL' ? 'url' : 'page'
		const type = (funnelsState.funnelNodes.length === 0 || type2 === 'url') ? 'acquisition' : 'conversion'
		const position = funnelsState.instance.project({ x: event.clientX, y: event.clientY - 40 })
		const newNode = getNode(id, data.imageUrl, data.name, position, type, type2)

		// update funnelsState
		funnelsDispatch({ type: 'SET_FUNNEL_NODES', value: funnelsState.funnelNodes.concat(newNode) })
		funnelsDispatch({ type: 'SET_ABOUT_TO_SAVE', value: true })

		gtag('event', 'add_node', { node_name: data.name, type, type2 })
	}

	const onClickBackground = () => {
		// close open menus
		if (disclosures.selectMenuDisclosure.isOpen) disclosures.selectMenuDisclosure.onClose()
		if (disclosures.accountMenuDisclosure.isOpen) disclosures.accountMenuDisclosure.onClose()
		if (disclosures.mobileMenuDisclosure.isOpen) disclosures.mobileMenuDisclosure.onClose()
	}

	useEffect(() => {
		if (bottomBarRef?.current) makeElementScrollable(bottomBarRef?.current)
	}, [bottomBarRef?.current])

	const nodeTypes = useMemo(() => ({ element: CanvasElement }), [])
	const edgeTypes = useMemo(() => ({ animated: true }), [])


	const onNodesChange = useCallback((c) => {
		const newNodes = applyNodeChanges(c, funnelsState.funnelNodes ?? [])
		const newEdges = (c[0].type === 'remove') ? funnelsState.funnelEdges.filter(r => r.target !== c[0].id) : funnelsState.funnelEdges
		funnelsDispatch({ type: 'SET_FUNNEL_NODES', value: calculateHits(newNodes, newEdges) })
		funnelsDispatch({ type: 'SET_FUNNEL_EDGES', value: newEdges })
	}, [funnelsState.funnelNodes, funnelsState.funnelEdges])

	const onEdgesChange = useCallback((c) => {
		const newEdges = applyEdgeChanges(c, funnelsState.funnelEdges ?? [])
		funnelsDispatch({ type: 'SET_FUNNEL_EDGES', value: newEdges })
	}, [funnelsState.funnelNodes, funnelsState.funnelEdges])

	const onSelectionChange = useCallback((e) => {
		funnelsDispatch({ type: 'SET_SELECTED_ELEMENTS', value: e })
	}, [])

	const onKeyDown = useCallback((e) => {
		if(e.keyCode === DELETE_KEY_CODE) {
			if (funnelsState.selectedElements.nodes.length !== 0) {
				const nodes = funnelsState.funnelNodes.filter(e => e.id !== funnelsState.selectedElements.nodes[0].id)
				funnelsDispatch({ type: 'SET_FUNNEL_NODES', value: nodes })
			}else if(funnelsState.selectedElements.edges.length !== 0) {
				const edges = funnelsState.funnelEdges.filter(e => e.id !== funnelsState.selectedElements.edges[0].id)
				funnelsDispatch({ type: 'SET_FUNNEL_EDGES', value: edges })
			}
			funnelsDispatch({ type: 'SET_SELECTED_ELEMENTS', value: null })
		}
	}, [funnelsState.selectedElements])

	return (
		<Box className='dndflow' height="100%" display="flex" flexDirection="column">
			<ReactFlowProvider  flex={1}>
				<Box onKeyDown={onKeyDown} flex="1" onClick={() => onClickBackground()}>
					<ReactFlow height="100%" edgeTypes={edgeTypes} nodeTypes={nodeTypes} nodes={funnelsState.funnelNodes ?? []} edges={funnelsState.funnelEdges ?? []} onConnect={onConnect}
						onNodesChange={onNodesChange} onEdgesChange={onEdgesChange} onInit={onInit} onDrop={onDrop} onDragOver={onDragOver} onSelectionChange={onSelectionChange} onNodeDragStop={onNodeDragStop}>
						{userState.token && 
							<Text w="100%" opacity={0.5} position="absolute" bottom="12px" textAlign="center" m="10px">{funnelsState.isSaving ? 'Saving...' : 'Saved'}</Text>}
						<BottomPanel/>
						<ProfitPanel />
						<Box position="absolute" inset={0}>
							<Flex marginTop="150px" justifyContent="center" opacity={0.25}>
								{funnelsState.isLoading ? <Spinner mt="100px" color="purple" size="lg" thickness="3px" /> : funnelsState.funnelNodes?.length === 0 && <CanvasBackground />}
							</Flex>
						</Box>
					</ReactFlow>
				</Box>
				<BottomBarContainer containerRef={bottomBarRef}/>
			</ReactFlowProvider>
		</Box>
	)
}
export default CanvasContainer
