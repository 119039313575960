import { useContext, useEffect } from 'react'

import { useToast, useDisclosure } from "@chakra-ui/react"

import axios from 'axios'

import { useRouter } from 'next/router'

import {debounce} from '../utils/client'
import { FunnelsContext, FunnelsProvider } from '../store/funnels'
import { UserContext } from '../store/user'
import {toastOptions} from '../utils/client/toast'

import GetStartedModal from '../components/modals/GetStarted'
import SettingsPanel from '../components/panels/SettingsPanel'
import CanvasContainer from '../components/canvas/CanvasContainer'
import TopBar from '../components/topbar/TopBar'
import { DesktopView, SelectProject, TabletView } from '../components/topbar/TopBarFunnels'

import dynamic from 'next/dynamic'

import SocialHead from '../components/head/SocialHead'

import 'reactflow/dist/style.css'
import 'reactflow/dist/base.css'

import { useGoogleLogin } from '@react-oauth/google'

const Layout = dynamic(() => import('../components/shared/Layout'), {ssr: false})

const Funnels = () => {
	
	const {state: funnelsState, dispatch: funnelsDispatch} = useContext(FunnelsContext)
	const {state: userState, dispatch: userDispatch, loginSuccess} = useContext(UserContext)
	
	const toast = useToast()
	const router = useRouter()

	const getStartedMenuDisclosure = useDisclosure()
	
	const saveFunnel = async (funnelId, elements) => {
		if (funnelId) {
			funnelsDispatch({ type: 'SET_IS_SAVING', value: true })
			await axios.put(`/api/funnels/${funnelId}`, { elements: elements }, { headers: { authorization: userState.token } })
			funnelsDispatch({ type: 'SET_IS_SAVING', value: false })
		} else {
			const { data } = await axios.post('/api/funnels/save', { elements: funnelsState.instance }, { headers: { authorization: userState.token } })
			router.push(`/funnels/${data.funnelId}`, null, { shallow: true })
		}
	}

	useEffect(() => {
		const status = userState.user?.subscriptionStatus
		if (status === 'active' && funnelsState.aboutToSave) {
			const elements = funnelsState.funnelNodes.concat(funnelsState.funnelEdges)
			debounce(() => {
				funnelsDispatch({ type: 'SET_ABOUT_TO_SAVE', value: false })
				saveFunnel(router.query.funnelId, elements)
			}, 2000)()
		}
	}, [funnelsState.aboutToSave])

	const getUserWithFunnels = async () => {
		// get user funnels
		const {data} = await axios.get(`/api/funnels/list`, { headers: { 'Content-Type': 'application/json', authorization: userState.token } })
		funnelsDispatch({ type: 'SET_FUNNELS', value: data.funnels })

		// set funnel
		const index = (funnelsState.funnelEdges?.length > 0  || funnelsState.funnelNodes?.length > 0) ? data.funnels.length - 1 : 0
		const funnelNodes = data.funnels[index].elements.filter(n => n.type === 'element')
		const funnelEdges = data.funnels[index].elements.filter(n => n.type === 'link')
		funnelsDispatch({ type: 'SET_FUNNEL_INDEX', value: index})
		funnelsDispatch({ type: 'SET_FUNNEL_NODES', value: funnelNodes})
		funnelsDispatch({ type: 'SET_FUNNEL_EDGES', value: funnelEdges})
		router.push(`/funnels/${data.funnels[index].id}`,null, { shallow: true })
	}

	const getSession = async (sessionId) => {
		const {data} = await axios.get(`/api/auth/session?session_id=${sessionId}`, { headers: { 'Content-Type': 'application/json' } })
		userDispatch({ type: 'SET_USER_TOKEN', value: data.token })
		toast({ title: "You changed plan", variant: 'subtle', description: "You have subscribed to the \"Premium Plan\"!", status: "success", ...toastOptions })
		router.push('/', null, { shallow: true })
	}
	
	const getFunnel = async (funnelId) => {
		funnelsDispatch({ type: 'SET_LOADING', value: true })
		const {data} = await axios.get(`/api/funnels/${funnelId}`, { headers: { 'Content-Type': 'application/json', authorization: userState.token } })
		const funnelNodes =  data.elements.filter(n => n.type === 'element')
		const funnelEdges =  data.elements.filter(n => n.type === 'link')
		funnelsDispatch({ type: 'SET_FUNNEL_NODES', value: funnelNodes})
		funnelsDispatch({ type: 'SET_FUNNEL_EDGES', value: funnelEdges})
		funnelsDispatch({ type: 'SET_LOADING', value: false })
		setTimeout(() => funnelsState.instance?.fitView({maxZoom: 1}), 50)
	}

	useEffect(() => {
		const sessionId = router.query.session_id
		if (sessionId && sessionId !== 'null') {
			getSession(sessionId)
		}
	}, [router.query.session_id])

	useEffect(() => {
		// get funnel
		const funnelId = router.query.funnelId
		if (funnelId && userState.token) {
			getFunnel(funnelId)
		} else if (!funnelsState.funnelEdges || !funnelsState.funnelNodes) {
			funnelsDispatch({ type: 'SET_FUNNEL_NODES', value: []})
			funnelsDispatch({ type: 'SET_FUNNEL_EDGES', value: []})
		}

		// redirect
		if (funnelId && !userState.token) router.push(`/`)
	}, [router.query.funnelId])

	useEffect(() => {
		if (userState.token) {
			getUserWithFunnels()
		}
	}, [userState.token])

	useEffect(() => {
		const isFirstTime = localStorage.getItem('first_time') !== 'false'
		if (isFirstTime) getStartedMenuDisclosure.onOpen()
	}, [])

	const onSuccess = (res) => {
        const instance = funnelsState.instance.toObject()
        const elements = instance.nodes.concat(instance.edges)
        loginSuccess(res, true, {type: "funnels", data: elements })
    }

	const login = useGoogleLogin({ flow: 'auth-code', onSuccess})

	return (
		<>
			<SocialHead/>
			<Layout>
				<GetStartedModal isOpen={getStartedMenuDisclosure.isOpen} onClose={() => {localStorage.setItem('first_time', false); getStartedMenuDisclosure.onClose()}}/>
				<TopBar SelectProject={SelectProject} DesktopView={DesktopView} TabletView={TabletView} elements={funnelsState.funnels} login={login}/>
				<CanvasContainer />
				{(funnelsState.selectedElements?.nodes.length > 0 || funnelsState.selectedElements?.edges.length > 0) && <SettingsPanel/>}
				{/* {(state.selectedElements.nodes.length > 0 || state.selectedElements.edges.length > 0) && <MidPanel/>} */}
			</Layout>
		</>
	)
}



const Index = () => {
	return (
		<FunnelsProvider>
			<Funnels/>
		</FunnelsProvider>
	)
} 

export default Index
