import { Badge, Box } from '@chakra-ui/react'

import {showBackground, background} from '../../data/steps'

const BottomBarElement = ({ imageUrl, name, isPrimary = false }) => {

	const onDragStart = (e) => {
		e.dataTransfer.setData('application/reactflow', JSON.stringify({ nodeType: 'input', imageUrl, name }))
		e.dataTransfer.effectAllowed = 'move'
	}

	return (
		// css grid gap does not work on mobile
		<Box minWidth="80px" cursor="pointer" position="relative" zIndex={0} margin="0 6px" onDragStart={onDragStart} draggable>
			{showBackground && <img src={background} alt='screen background' draggable="false" style={{ position: 'absolute', zIndex: -2 }} />}
			<img src={imageUrl} alt='screen' draggable="false" />
			<Box textAlign="center">
				{!isPrimary ? 
					<span style={{ fontSize: '12px', color: '#636363', fontWeight: 'bold' }}>{name}</span> :
					<Badge colorScheme="purple">URL</Badge>}
			</Box>
		</Box>
	)
}
export default BottomBarElement